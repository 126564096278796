import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../components/layout'
import { getSeperatorWithHeight, getColOf3 } from '../components/text_style'
import { Document, Page } from 'react-pdf'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import {
  displayFont,
  displayFontWeight,
} from '../components/hci_foundation/hci_foundation_style'
import { siteMainAccentColor } from '../components/site_style'
import {
  textFont,
  textFontWeight,
} from '../components/hci_foundation/hci_foundation_style'
import selfImage from '../images/myself.jpg'
import uwmadison from '../images/color-flush-UWlogo-print.png'
import gtech from '../images/gt-logo-gold.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from './footer'
import SEO from '../components/seo'

let AboutMainTextBold = styled.span`
  font-weight: ${textFontWeight.bold};
`

let AboutMainText = styled.p`
  font-family: ${textFont};
  font-weight: ${textFontWeight.regular};
  font-size: 1.25rem;
  color: #525252;
  letter-spacing: -0.5px;
  line-height: 200%;
`

let HobbyText = styled.p`
  font-family: ${textFont};
  font-weight: ${textFontWeight.regular};
  font-size: 1rem;
  color: #525252;
  letter-spacing: -0.5px;
  line-height: 200%;
  text-align: center;
  
`

let AboutTitle = styled.div`
  font-family: ${displayFont};
  font-size: 3rem;
  font-weight: ${displayFontWeight.regular};
  color: ${siteMainAccentColor};
`

let Header = styled.div`
  font-family: ${displayFont};
  font-size: 18px;
  font-weight: ${displayFontWeight.bold};
  color: #525252;
  letter-spacing: -0.5px;
  line-height: 30px;
`

const About = () => (
  <Layout>
    <SEO title="About Me" />
    {getSeperatorWithHeight(50)}

    <Row>
      <Col md={2} />

      <Col md={8}>
        <AboutTitle>About me</AboutTitle>
      </Col>
    </Row>

    {getSeperatorWithHeight(40)}

    <Row>
      <Col md={2} />

      <Col md={5} style={{ paddingRight: `70px` }}>
        <AboutMainText>
          Hi, there! I’m <AboutMainTextBold> Junjie Xu (JJ) </AboutMainTextBold>{' '}
          from Shanghai, China. As a UX designer, I believe that the digital
          technology when designed for people can make a difference. I want to
          design products to help people explore and learn the world and enjoy
          the life. I’m currently studying Human Computer-Interaction at Georgia
          Tech as a second year master student.
        </AboutMainText>

        <AboutMainText>
          My work interests lie in <strong>interaction design</strong> and{' '}
          <strong>information visualization</strong>, both require coding to express
          thoughts and creativity. My master project focuses on designing a
          teacher dashboard for a game-based assessment. Education technology is
          also one of main interests I have. I’m fond of tutoring others on
          programming.
        </AboutMainText>

        <AboutMainText>
          In my spare time, I like to feed my curiosity with{' '}
          <AboutMainTextBold> books </AboutMainTextBold>, my desire to explore
          with <AboutMainTextBold> road trips </AboutMainTextBold> and my
          imagination of impossibility with{' '}
          <AboutMainTextBold> video games</AboutMainTextBold>.
        </AboutMainText>

        {/* <AboutMainText>
          Academically, I’m interested in how to solve problems with the combined power of three fields, Computer Science, Psychology, and Design .
        </AboutMainText>

        <AboutMainText>
          <AboutMainTextBold> Computer Science: </AboutMainTextBold>
          You can call me a nerd in software development in my undergraduate. My
          experience expanded from backend to mobile front-end and also to
          creating animations in WebGL. My experience in full-stack software
          engineering not only provides me with insights in how different technologies
          suit different needs, but also pushes me to think how to create software that have impact on users.
        </AboutMainText>

        <AboutMainText>
          <AboutMainTextBold> Psychology: </AboutMainTextBold>
          Psychology research sates my curiosity in understanding who we are as an individual and how
          we develop into who we are. My interest towards human development, mental health and different culture
          guide my view that the technology should assist human. I’m also familiar with different psychology
          research methods. Be careful, I can read your mind :p .


        </AboutMainText>

        <AboutMainText>
          <AboutMainTextBold> Design: </AboutMainTextBold>
          My experience with design starts from stage lighting design in my high school and undergraduate.
          I like shadow and light. I decide to pick up some formal training in design at Georgia Tech. I love the
          design process which empathizes on solving problems in a creative way.
        </AboutMainText> */}
      </Col>
      <Col md={3}>
        <img src={selfImage} style={{borderRadius:"8px"}} className="img-fluid" alt={'hello'} />

        <Row
          style={{ marginLeft: `0px`, marginTop: '10px', marginBottom: '20px' }}
        >
          <Header>Education</Header>
        </Row>
        <Row style={{ marginLeft: `0px` }}>
          {/*style={{width:`55px`}}*/}
          <Col xs={6}>
            <img src={uwmadison} className="img-fluid" alt={'hello'} />
          </Col>

          <Col xs={6}>
            {/*style={{height:`150px`}}*/}
            <img src={gtech} className="img-fluid" alt={'hello'} />
          </Col>
        </Row>

        <Row
          style={{ marginLeft: `0px`, marginTop: '10px', marginBottom: '20px' }}
        >
          <Header>Hobby</Header>
        </Row>
        <Row style={{ marginLeft: `0px` }}>
          {/*style={{width:`55px`}}*/}

          <Col
            xs={4}
            style={{ display:"flex", flexDirection:"column", alignItems:"flex-start"}}
          >
            <div style={{ color: siteMainAccentColor,display:"flex", flexDirection:"column", alignItems:"center"}}>
              <FontAwesomeIcon size="2x" icon={'car-side'} />
              <HobbyText>Road Trip</HobbyText>
            </div>
          </Col>
          <Col
            xs={4}
            style={{ color: siteMainAccentColor, display:"flex", flexDirection:"column", alignItems:"flex-start"}}
          >
             <div style={{ color: siteMainAccentColor,display:"flex", flexDirection:"column", alignItems:"center"}}>
              <FontAwesomeIcon size="2x" icon={'book'} />
              <HobbyText>Book</HobbyText>
            </div>
          </Col>

          <Col
            xs={4}
            style={{ color: siteMainAccentColor, display:"flex", flexDirection:"column", alignItems:"flex-start"}}
          >
             <div style={{ color: siteMainAccentColor,display:"flex", flexDirection:"column", alignItems:"center"}}>
              <FontAwesomeIcon size="2x" icon={'gamepad'} />
              <HobbyText>Video Game</HobbyText>
            </div>
          </Col>
        </Row>

        <Row style={{ marginLeft: `0px`, marginTop: `40px` }}>
          <Col
            xs={4}
            style={{ color: siteMainAccentColor, display:"flex", flexDirection:"column", alignItems:"flex-start"}}
          >
            {/*style={{height:`150px`}}*/}
            <div style={{ color: siteMainAccentColor,display:"flex", flexDirection:"column", alignItems:"center"}}>
              <FontAwesomeIcon size="2x" icon={'skiing'} />
              <HobbyText>Sking</HobbyText>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>

    {getSeperatorWithHeight(300)}

    <Footer />
  </Layout>
)

export default About
